<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <v-card-title class="text-h5">Change password</v-card-title>

      <v-card-text>
        <v-form ref="changePasswordForm">
          <v-text-field
            label="Current Password"
            :rules="passwordRules"
            v-model="changePasswordForm.current_password"
            :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showCurrentPassword ? 'text' : 'password'"
            @click:append="showCurrentPassword = !showCurrentPassword"
            outlined
          ></v-text-field>
          <v-text-field
            label="New Password"
            :rules="newPasswordRules"
            v-model="changePasswordForm.new_password"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showNewPassword ? 'text' : 'password'"
            @click:append="showNewPassword = !showNewPassword"
            outlined
          ></v-text-field>
          <v-text-field
            label="Confirm Password"
            :rules="confirmPasswordRules"
            v-model="changePasswordForm.confirm_password"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            outlined
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="var(--primary)"
          dark
          :loading="loading"
          @click="changePassword"
        >
          Change password</v-btn
        >
        <v-btn color="grey darken-1" dark @click="close"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog'],
  data() {
    return {
      changePasswordForm: {
        current_password: '',
        new_password: '',
        confirm_password: '',
      },
      loading: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      passwordRules: [(v) => !!v || 'Password is required'],
      newPasswordRules: [
        (v) => !!v || 'Password is required',
        (v) =>
          (v && v.length >= 8 && v.length <= 64) ||
          'Password must be between 8-64 characters long',
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).*$/gm.test(v) ||
          'New password must contain 1 special character(@#$%^&+=!), 1 uppercase and 1 lowercase character',
      ],
      confirmPasswordRules: [
        (v) => !!v || 'Password is required',
        (v) =>
          (v && v.length >= 8 && v.length <= 64) ||
          'Password must be between 8-64 characters long',
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).*$/gm.test(v) ||
          'New password must contain 1 special character(@#$%^&+=!), 1 uppercase and 1 lowercase character',
        () =>
          this.changePasswordForm.new_password ===
            this.changePasswordForm.confirm_password ||
          'Passwords do not match',
      ],
    }
  },
  methods: {
    close() {
      this.$emit('refresh')
      this.$emit('update:dialog', false)
    },
    async changePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        this.loading = true
        try {
          await this.$store.dispatch('change_password', this.changePasswordForm)
          this.$root.snackbar.show({
            message: 'Password changed successfully',
            color: 'success',
          })
          this.close()
        } catch (error) {
          let errorMsg = error.response
            ? error.response.data.message
            : 'Network error'
          this.$root.snackbar.show({
            message: errorMsg,
            color: 'error',
          })
        } finally {
          this.loading = false
        }
      } else {
        this.$root.snackbar.show({
          message: 'Required fields are missing.',
          color: 'error',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
  padding-top: 15px;
  padding-bottom: 15px;
}
label {
  margin-bottom: 5px;
  display: inline-block;
}
</style>